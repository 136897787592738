import React, { FC, useState } from "react"

import { Alert, Button, Modal, Space, Typography } from "antd"

import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"

interface Props {
  open: boolean
  setOpen: (v: boolean) => any
}

const TelegramModal: FC<Props> = ({ open, setOpen }) => {

  const [ loading, setLoading ] = useState(false)

  const { telegram } = useTypedSelector((state) => state.currentUser)
  const { currentUserConnectTelegram, currentUserDisconnectTelegram } = useActions()

  return (
    <Modal
      title="Статус уведомлений"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Space direction="vertical" style={{ margin: "15px 0" }}>
        <Typography>
          Бот проверяет наличие новых объявлений (Авито, Циан) в базе согласно Вашей локации и как только они появятся, даст Вам об этом знать.
        </Typography>
        <Alert
          message={ telegram ? "Telegram подключен" : "Telegram не подключен" }
          type={ telegram ? "success" : "info" }
          style={{ margin: "5px 0" }}
          showIcon
        />
        <Button
          type="primary"
          loading={loading}
          onClick={telegram ? () => {
            setLoading(true)
            currentUserDisconnectTelegram(() => {
              setLoading(false)
            })
          } : () => {
            setLoading(true)
            currentUserConnectTelegram(() => {
              setLoading(false)
            })
          }}
        >
          { telegram ? "Отключить Telegram" : "Подключить Telegram" }
        </Button>
      </Space>
    </Modal>
  )
}

export default TelegramModal
