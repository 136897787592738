import React, { FC, useEffect } from "react"

import { DatePicker, Form, Input, Modal, Select } from "antd"

import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { showFormErrors, showSuccessNotification } from "../../../utils/ui"
import dayjs from "dayjs"

interface Props {
    open: boolean
    setOpen: (value: boolean) => void
}

const AddModal: FC<Props> = ({ open, setOpen }) => {

  const [ form ] = Form.useForm()

  const { isLoading, listsLoading, lists } = useTypedSelector((state) => state.access)
  const { accessCreate, accessFetchData } = useActions()

  const addAccess = (values: any) => {
    const data = {
      ...values,
      access_to: dayjs(values.access_to).format("YYYY-MM-DD"),
    }

    accessCreate(data, () => {
      setOpen(false)
      showSuccessNotification("Доступ добавлен")
      accessFetchData()
    }, (errors) => {
      showFormErrors(form, errors)
    })
  }

  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [ open ])

  return (
    <Modal
      title="Добавление доступа"
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
      okText="Добавить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={addAccess}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Логин"
          name="username"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Группа"
          name="group_id"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select loading={listsLoading}>
            {
              lists?.groups?.map((item: {id: string, name: string}, index: number) => (
                <Select.Option value={item.id} key={index}>
                  {item.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="Локация"
          name="location_id"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select loading={listsLoading}>
            {
              lists?.location?.map((item: {id: string, name: string}, index: number) => (
                <Select.Option value={item.id} key={index}>
                  {item.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="Роль"
          name="role_id"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select loading={listsLoading}>
            {
              lists?.roles?.map((item: {id: string, name: string}, index: number) => (
                <Select.Option value={item.id} key={index}>
                  {item.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="IP адрес"
          name="ip_address"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Доступ до"
          name="access_to"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <DatePicker
            format="DD.MM.YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Комментарий"
          name="comment"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Агентство"
          name="parent_id"
        >
          <Select loading={listsLoading}>
            {
              lists?.agencies?.map((item: {id: string, name: string}, index: number) => (
                <Select.Option value={item.id} key={index}>
                  {item.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default AddModal
