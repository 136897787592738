import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IAccessRow, IAccessState } from "./types"

const initialState: IAccessState = {
  accessData: [],
  isLoading: false,
  isUpdate: false,
  lists: null,
  listsLoading: false,
}

export const accessSlice = createSlice({
  name: "access",
  initialState: initialState,
  reducers: {
    setAccessData: (state, action: PayloadAction<IAccessRow[]>) => {
      state.accessData = action.payload
    },
    setAccessLists: (state, action: PayloadAction<{[keys: string]: any}>) => {
      state.lists = action.payload
    },
    setAccessListsLoading: (state, action: PayloadAction<boolean>) => {
      state.listsLoading = action.payload
    },
    setAccessLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAccessUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const accessSliceReducer = accessSlice.reducer
export const accessSliceActions = accessSlice.actions
