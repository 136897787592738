import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IAdsData, IFavoriteMeta, IFavoriteState } from "./types"

const initialState: IFavoriteState = {
  isLoading: false,
  isFetching: true,
  tableData: [],
  tableMetaData: {
    total: 0,
    totalPages: 0,
    start: 0,
    end: 0,
    currentPage: 1,
    pageSize: 30
  },
}

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState: initialState,
  reducers: {
    setFavoriteLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setFavoriteFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload
    },
    setFavoriteTableData: (state, action: PayloadAction<{rows: IAdsData[], meta: IFavoriteMeta}>) => {
      state.tableData = action.payload.rows
      state.tableMetaData = action.payload.meta
    },
    setFavoriteTableMetaData: (state, action: PayloadAction<IFavoriteMeta>) => {
      state.tableMetaData = action.payload
    },
  }
})

export const favoriteSliceActions = favoriteSlice.actions
export const favoriteSliceReducer = favoriteSlice.reducer

