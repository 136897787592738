import React, { FC } from "react"

import { Navigate } from "react-router"

import { routeNames } from "../../../constants/routes"

interface Props {
  passIf: boolean
  navigateTo?: string
  children?: any
}

const ProtectedRouteWrapper: FC<Props> = ({ children, passIf, navigateTo }) => {

  return passIf ? (
    <>
      {children}
    </>
  ) : (
    <>
      {
        navigateTo ? <Navigate to={navigateTo} /> : null
      }
    </>
  )
}

export default ProtectedRouteWrapper
