
export const cleanObject = (obj: any) => {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export const updateArrayItem = (id: number | string, newValue: any, array: any[]) => {
  const newArray = array.map((item) => {
    if (item.id === id) {
      return { ...item, ...newValue }
    }
    return item
  })
    
  return newArray
}