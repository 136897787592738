import { accessSliceReducer } from "./slices/access/accessSlice"
import { accessActions } from "./slices/access/actions"
import { adsActions } from "./slices/ads/actions"
import { adsSliceReducer } from "./slices/ads/adsSlice"
import { appActions } from "./slices/app/actions"
import { appSliceReducer } from "./slices/app/appSlice"
import { authActions } from "./slices/auth/actions"
import { authSliceReducer } from "./slices/auth/authSlice"
import { blActions } from "./slices/black-list/actions"
import { blSliceReducer } from "./slices/black-list/blSlice"
import { currentUserActions } from "./slices/currentUser/actions"
import { currentUserSliceReducer } from "./slices/currentUser/currentUserSlice"
import { favoriteActions } from "./slices/favorite/actions"
import { favoriteSliceReducer } from "./slices/favorite/favoriteSlice"
import { itemActions } from "./slices/items/actions"
import { itemSliceReducer } from "./slices/items/itemSlice"
import { paramsActions } from "./slices/params/actions"
import { paramsSliceReducer } from "./slices/params/paramsSlice"
import { staffActions } from "./slices/staff/actions"
import { staffSliceReducer } from "./slices/staff/staffSlice"

export const rootAction = {
  ...appActions,
  ...authActions,
  ...currentUserActions,

  ...staffActions,
  ...itemActions,
  ...accessActions,
  ...paramsActions,
  ...adsActions,
  ...blActions,
  ...favoriteActions,
}

export const rootReducer = {
  app: appSliceReducer,
  auth: authSliceReducer,
  currentUser: currentUserSliceReducer,

  staff: staffSliceReducer,
  item: itemSliceReducer,
  access: accessSliceReducer,
  params: paramsSliceReducer,
  ads: adsSliceReducer,
  bl: blSliceReducer,
  favorite: favoriteSliceReducer,
}
