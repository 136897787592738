import axios, { AxiosResponse } from "axios"

import { axiosRefreshConfig } from "../middleware"
import {
  API_AUTH_LOGIN,
  API_AUTH_LOGOUT,
  // API_AUTH_RECOVERY,
  API_AUTH_REFRESH,
  // API_AUTH_RESET
} from "../urls"

export const authApi = {

  login: async (username: string, password: string): Promise<AxiosResponse> => {
    const data = {
      username: username,
      password,
    }

    return axios.post(API_AUTH_LOGIN, data, axiosRefreshConfig())
  },

  logout: async (token: string): Promise<AxiosResponse> => {
    return axios.get(API_AUTH_LOGOUT, {
      headers: {
        Token: token,
        withCredentials: true,
      },
    })
  },

  refreshToken: async (): Promise<AxiosResponse> => {
    return axios.get(API_AUTH_REFRESH, axiosRefreshConfig())
  }
}
