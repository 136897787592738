import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ICurrentUser, ILocation, IUserState } from "./types"
import { ICall } from "../../../types/ads"


const initialState: IUserState = {
  id: 0,
  name: "",
  username: "",
  group: 0,
  role: null,
  user_phone_status: 4,
  user_app_status: 1,
  user_locations_list: [],
  locationsLoading: false,
  currentCall: null,
  access: undefined,
  telegram: false,
}

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: initialState,
  reducers: {
    currentUserSetData: (state, action: PayloadAction<ICurrentUser>) => {
      state.id = action.payload.id
      state.name = action.payload.name
      state.username = action.payload.username
      state.group = action.payload.group
      state.role = action.payload.role
      state.access = action.payload.access
      state.telegram = action.payload.telegram
    },
    currentUserClearData: (state) => {
      state.id = initialState.id
      state.name = initialState.name
      state.username = initialState.username
      state.group = initialState.group
      state.role = initialState.role
      state.access = initialState.access
      state.telegram = initialState.telegram
    },
    currentUserSetCall: (state, action: PayloadAction<ICall | null>) => {
      state.currentCall = action.payload
    },
    currentUserSetPhoneStatus: (state, action: PayloadAction<2 | 3 | 4>) => {
      state.user_phone_status = action.payload
    },
    currentUserSetAppStatus: (state, action: PayloadAction<1 | 2>) => {
      state.user_app_status = action.payload
    },
    currentUserSetLocationList: (state, action: PayloadAction<ILocation[]>) => {
      state.user_locations_list = action.payload
    },
    currentUserSetLocationsLoading: (state, action: PayloadAction<boolean>) => {
      state.locationsLoading = action.payload
    },
  }
})

export const currentUserSliceActions = currentUserSlice.actions
export const currentUserSliceReducer = currentUserSlice.reducer
