import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import {
  API_ACCESS_ADD,
  API_ACCESS_DELETE,
  API_ACCESS_EDIT,
  API_ACCESS_EDIT_INFO,
  API_ACCESS_GET,
  API_ACCESS_LISTS,
  API_PARAMS_UPDATE
} from "../urls"

export const accessApi = {
  fetchData: (): Promise<AxiosResponse> => {
    return axios.get(API_ACCESS_GET, axiosConfigAuth())
  },

  create: (data: any): Promise<AxiosResponse> => {
    return axios.post(API_ACCESS_ADD, data, axiosConfigAuth())
  },

  updateInfo: (id: string | number): Promise<AxiosResponse> => {
    return axios.get(`${API_ACCESS_EDIT_INFO}/${id}`, axiosConfigAuth())
  },

  update: (data: any): Promise<AxiosResponse> => {
    return axios.post(API_ACCESS_EDIT, data, axiosConfigAuth())
  },

  delete: (id: string | number): Promise<AxiosResponse> => {
    return axios.get(`${API_ACCESS_DELETE}/${id}`, axiosConfigAuth())
  },

  changeMode: (mode: 1 | 0): Promise<AxiosResponse> => {
    const data = {
      id: 1,
      value: mode
    }

    return axios.post(API_PARAMS_UPDATE, data, axiosConfigAuth())
  },

  getAccessLists: (): Promise<AxiosResponse> => {
    return axios.get(API_ACCESS_LISTS, axiosConfigAuth())
  },
}
