import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { bindActionCreators } from "redux"

import { authApi } from "./endpoints/authApi"
import { rootAction } from "../redux/root"
import { TAppDispatch } from "../redux/store"
import { getAccessToken, isAccessTokenExpired, saveAccessToken } from "../utils/auth"
import { showErrorNotification } from "../utils/ui"

export const axiosConfigAuth = (headers?: any, options?: any): AxiosRequestConfig => {

  return {
    headers: {
      Token: `${getAccessToken()}`,
      ...headers
    },
    ...options
  }
}

export const axiosRefreshConfig = (options?: any): AxiosRequestConfig => {
  return {
    withCredentials: true,
    ...options
  }
}

export const handleResponseError = (dispatch: TAppDispatch, error: AxiosError, messages?: {[key: number]: string}) => {

  const { authLogout, setAppGlobalError } = bindActionCreators(rootAction, dispatch)

  if (error.response) {

    if (error.response?.status === 401) {
      authLogout(() => {
        showErrorNotification("Сессия истекла. Пожалуйста, войдите снова.")
      })
      return
    } else if (error.response?.status === 500) {
      setAppGlobalError(true)
      return
    }

    if (messages) {
      Object.entries(messages || []).forEach(([ key, val ]) => {
        if (`${error.response?.status}` === key) {
          showErrorNotification(val)
          return
        }
      })
      return
    }

    showErrorNotification("Произошла ошибка, повторите попытку позже.")
    return
  } else {
    showErrorNotification("Произошла ошибка, повторите попытку позже.")
    return
  }
}

export const initAuthInterceptor = async () => {

  axios.interceptors.request.use(async (config) => {
    const accessToken = getAccessToken()

    if (config.url?.includes("logout")) {
      return config
    }

    if (config.url?.includes("login")) {
      return config
    }

    if (config.url?.includes("refresh")) {
      config.headers.withCredentials = true
      return config
    }

    if (isAccessTokenExpired(accessToken)) {
      await authApi.refreshToken()
        .then((response) => {
          const newToken = response.data.access_token

          config.headers.Token = newToken
          saveAccessToken(newToken)
        })
        .catch(() => {
          return Promise.reject({
            response: {
              status: 401,
              message: "Unauthorized",
            }
          })
        })
    } else {
      config.headers.Token = accessToken
    }

    return config
  }, (error) => {
    return Promise.resolve(error)
  })
}
