import React, { FC } from "react"

interface IProps {
    title?: string
    color?: string
}

const MetroView: FC<IProps> = ({ title, color }) => {
  return title && color ? (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <div
        style={{ 
          width: 10, 
          height: 10,
          borderRadius: "50%",
          backgroundColor: color,
          marginRight: 10
        }} 
      />
      <span>{title}</span>
    </div>
  ) : null
}

export default MetroView