import React, { FC } from "react"

import MainLayout from "../../components/layouts/MainLayout"
import AdsPage from "../../components/pages/ads/AdsPage"

const Home: FC = () => {
  return (
    <MainLayout pageTitle="Объявления">
      <div className="page-wrapper">
        {/* <PageHead title="Объявления" /> */}

        <AdsPage />
      </div>
    </MainLayout>
  )
}

export default Home
