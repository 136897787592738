import { useTypedSelector } from "./useTypedSelector"

// roles:
// 1 - admin
// 2 - user
export const useAllowedRoles = (allowedRoles: number[]): boolean => {
  const { role } = useTypedSelector((state) => state.currentUser)

  const containsRoles = (where: number[], what: number) => {
    return where.includes(what, 0)
  }

  return !!role && containsRoles(allowedRoles, role)
}
