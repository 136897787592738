import React, { FC, useEffect, useState } from "react"

import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Input, Modal, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./AddModal"
import EditModal from "./EditModal"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import ColorTag from "../../ui/color-tag/ColorTag"
import { allTagColors, errorTagColor, successTagColor } from "../../../constants/colors"

const AccessPage: FC = () => {

  const [ addModalOpen, setAddModalOpen ] = useState(false)
  const [ openEditModal, setOpenEditModal ] = useState(false)
  const [ editData, setEditData ] = useState({
    id: 0,
  })
  const [ tableData, setTableData ] = useState<any[]>([])

  const { accessData, isLoading } = useTypedSelector((state) => state.access)
  const { accessFetchData, accessDelete, accessGetLists } = useActions()

  useEffect(() => {
    accessFetchData()
    accessGetLists()
  }, [])

  useEffect(() => {
    setTableData(accessData)
  }, [ accessData ])

  const accessColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "IP адрес",
      dataIndex: "ip_address",
      key: "ip_address",
    },
    {
      title: "Сотрудников (всего / платных)",
      dataIndex: "employees_count",
      key: "employees_count",
      width: 150,
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      render: (value) => (
        <ColorTag
          color={
            value === "Админ" ? allTagColors[2] :
              value === "Одиночка" ? successTagColor :
              value === "Уведомления" ? allTagColors[3] :
              allTagColors[1]
          }
        >
          {value}
        </ColorTag>
      ),
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Доступ до",
      dataIndex: "access_to",
      key: "access_to",
      render: (value, record) => (
        <ColorTag color={record.expired ? errorTagColor : successTagColor}>
          {value}
        </ColorTag>
      ),
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (value, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditData({
                id: record.id
              })
              setOpenEditModal(true)
            }}
          />

          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить доступ?",
                icon: <ExclamationCircleOutlined/>,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                centered: true,
                onOk() {
                  accessDelete(record.id, () => {
                    accessFetchData()
                  })
                },
              })
            }}
            danger
          />
        </Space>
      )
    },
  ]

  const searchTable = (value: string) => {
    const searchString = value.toLowerCase()

    if (value) {
      const _data = tableData.filter((val: any) => {
        return (
          `${val.id}`.toLowerCase().includes(searchString) ||
          `${val.name}`.toLowerCase().includes(searchString) ||
          `${val.ip_address}`.toLowerCase().includes(searchString) ||
          `${val.employees_count}`.toLowerCase().includes(searchString) ||
          `${val.comment}`.toLowerCase().includes(searchString) ||
          `${val.access_to}`.toLowerCase().includes(searchString)
        )
      })

      setTableData(_data)
    } else {
      setTableData(accessData)
    }
  }

  return (
    <div className="page-content">
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <Input
          placeholder="Поиск по таблице..."
          onChange={(e) => searchTable(e.target.value)}
          style={{ width: 300 }}
          allowClear
        />

        <Button type="primary" onClick={() => setAddModalOpen(true)}>
          Добавить доступ
        </Button>
      </div>

      <Table
        dataSource={tableData}
        columns={accessColumns}
        scroll={{ x: 870 }}
        style={{ margin: "24px 0" }}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        // scroll={{ x: 1300 }}
        pagination={false}
        size="small"
        bordered
      />

      {/*{*/}
      {/*  accessData.access ?*/}
      {/*    <Button*/}
      {/*      type="default"*/}
      {/*      onClick={() => accessChangeMode(0, () => {accessFetchData()})}*/}
      {/*      loading={isLoading}*/}
      {/*      danger*/}
      {/*    >*/}
      {/*      Выключить авторизацию*/}
      {/*    </Button>*/}
      {/*    :*/}
      {/*    <Button*/}
      {/*      type="default"*/}
      {/*      onClick={() => accessChangeMode(1, () => {accessFetchData()})}*/}
      {/*      loading={isLoading}*/}
      {/*      className="btn__success"*/}
      {/*    >*/}
      {/*      Включить авторизацию*/}
      {/*    </Button>*/}
      {/*}*/}

      <AddModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
      />

      <EditModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        id={editData.id}
      />
    </div>
  )
}

export default AccessPage
