import React, { FC, useEffect, useState } from "react"

import { EditOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import EditModal from "./EditModal"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

const CallsSettingsPage: FC = () => {

  const [ openEditModal, setOpenEditModal ] = useState(false)
  const [ editData, setEditData ] = useState({
    id: 0,
    desc: "",
    name: "",
    value: ""
  })

  const { paramsData, isLoading } = useTypedSelector((state) => state.params)
  const { paramsFetchData } = useActions()

  useEffect(() => {
    paramsFetchData()
  }, [])

  const staffColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Значение",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (value, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditData({
                id: record.id,
                name: record.name,
                value: record.value,
                desc: record.description
              })
              setOpenEditModal(true)
            }}
          />
        </Space>
      )
    },
  ]

  return (
    <div className="page-content">
      <Table
        dataSource={paramsData}
        columns={staffColumns}
        scroll={{ x: 870 }}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        pagination={false}
        size="small"
        bordered
      />

      <EditModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        id={editData.id}
        name={editData.name}
        desc={editData.desc}
        value={editData.value}
      />
    </div>
  )
}

export default CallsSettingsPage
