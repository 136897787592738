
export const routeNames = {
  // main
  home: "/",
  access: "/access",
  staff: "/staff",
  settings_call: "/settings/calls",
  settings_bl: "/settings/black-list",
  favorites: "/favorites",

  // extra
  notFound: "/404",
  error: "/error",

  // auth
  login: "/login",
  resetPassword: "/reset-password",
  restorePassword: "/restore",
}
