import { accessSliceActions } from "./accessSlice"
import { accessApi } from "../../../api/endpoints/accessApi"
import { handleResponseError } from "../../../api/middleware"
import { TAppDispatch } from "../../store"

export const accessActions = {
  ...accessSliceActions,

  accessFetchData: () => async (dispatch: TAppDispatch) => {
    dispatch(accessActions.setAccessLoading(true))

    accessApi.fetchData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(accessActions.setAccessData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(accessActions.setAccessLoading(false))
      })
  },

  accessCreate: (data: any, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
    dispatch(accessActions.setAccessLoading(true))

    accessApi.create(data)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          onFormError(err.response.data)
        }

        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(accessActions.setAccessLoading(false))
      })
  },

  accessUpdate: (data: any, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
    dispatch(accessActions.setAccessLoading(true))

    accessApi.update(data)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          onFormError(err.response.data)
        }

        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(accessActions.setAccessLoading(false))
      })
  },

  accessDelete: (id: string | number, onFinish: () => void) => async (dispatch: TAppDispatch) => {
    dispatch(accessActions.setAccessLoading(true))

    accessApi.delete(id)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(accessActions.setAccessLoading(false))
      })
  },

  accessChangeMode: (mode: 0 | 1, onFinish: () => void) => async (dispatch: TAppDispatch) => {
    dispatch(accessActions.setAccessLoading(true))

    accessApi.changeMode(mode)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(accessActions.setAccessLoading(false))
      })
  },

  accessGetLists: () => async (dispatch: TAppDispatch) => {
    dispatch(accessActions.setAccessListsLoading(true))

    accessApi.getAccessLists()
      .then((response) => {
        if (response.status === 200) {
          dispatch(accessActions.setAccessLists(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(accessActions.setAccessListsLoading(false))
      })
  }
}
