import React, { FC } from "react"

import MainLayout from "../../components/layouts/MainLayout"
import PageHead from "../../components/page-header/PageHeader"
import FavoritePage from "../../components/pages/favorite/FavoritePage"

const Favorites: FC = () => {
  return (
    <MainLayout pageTitle="Избранное">
      <div className="page-wrapper">
         <PageHead title="Избранное" />

        <FavoritePage />
      </div>
    </MainLayout>
  )
}

export default Favorites
