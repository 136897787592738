import { staffSliceActions } from "./staffSlice"
import { staffApi } from "../../../api/endpoints/staffApi"
import { handleResponseError } from "../../../api/middleware"
import { ICreateStaffPayload, IUpdateStaffPayload } from "../../../types/staff"
import { TAppDispatch } from "../../store"

export const staffActions = {
  ...staffSliceActions,

  staffFetchData: (finalAction?: () => void) => async (dispatch: TAppDispatch) => {
    dispatch(staffActions.setStaffLoading(true))

    staffApi.fetchData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(staffActions.setStaffData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(staffActions.setStaffLoading(false))
        finalAction && finalAction()
      })
  },

  staffCreateUser: (data: ICreateStaffPayload, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
    dispatch(staffActions.setStaffLoading(true))

    staffApi.createUser(data)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          onFormError(err.response.data)
        }

        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(staffActions.setStaffLoading(false))
      })
  },

  staffUpdateUser: (data: IUpdateStaffPayload, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
    dispatch(staffActions.setStaffLoading(true))

    staffApi.updateUser(data)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          onFormError(err.response.data)
        }

        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(staffActions.setStaffLoading(false))
      })
  },

  staffDelete: (id: string | number, onFinish: () => void) => async (dispatch: TAppDispatch) => {
    dispatch(staffActions.setStaffLoading(true))

    staffApi.deleteUser(id)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(staffActions.setStaffLoading(false))
      })
  }
}
