import React, { FC } from "react"

import { ScheduleOutlined, LikeOutlined, DislikeOutlined, LoadingOutlined } from "@ant-design/icons"
import { Space, Card, Statistic, Spin } from "antd"

import { ITableBanners } from "../../../redux/slices/ads/types"

interface IProps {
  bannerData: ITableBanners
  isLoading: boolean
  showFull?: boolean
}

const AdsBanners: FC<IProps> = ({ bannerData, isLoading, showFull = false }) => {
  const cardWidth = 180

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <Space direction="horizontal">
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Всего объявлений"
            value={bannerData.total}
            precision={0}
            prefix={<ScheduleOutlined />}
          />
        </Card>
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Наших квартир"
            value={bannerData.answered}
            precision={0}
            prefix={<LikeOutlined />}
          />
        </Card>
        {showFull &&
          <>
            <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
              <Statistic
                title="Не снял"
                value={bannerData.unanswered}
                precision={0}
                prefix={<DislikeOutlined />}
              />
            </Card>
            <Card bordered bodyStyle={{padding: "10px 20px", width: cardWidth}}>
              <Statistic
                title="Не первые"
                value={bannerData.not_first}
                precision={0}
                prefix={<DislikeOutlined />}
              />
            </Card>
            <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
              <Statistic
                title="Не дозвонился"
                value={bannerData.not_call}
                precision={0}
                prefix={<DislikeOutlined />}
              />
            </Card>
          </>
        }
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Конверсия"
            value={bannerData.conversion}
            precision={0}
          />
        </Card>
      </Space>
    </Spin>
  )
}

export default AdsBanners
