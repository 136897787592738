import React, { FC, useEffect, useState } from "react"

import "antd/dist/reset.css"
import "./styles/root.scss"

import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

import { API_WS_MAIN } from "./api/urls"
import CallWrapper from "./components/wrappers/callWrapper/CallWrapper"
import GlobalErrorWrapper from "./components/wrappers/global-error-wrapper/GlobalErrorWrapper"
import { superAdminRole } from "./constants/roles"
import { useActions } from "./hooks/useActions"
import { useTypedSelector } from "./hooks/useTypedSelector"
import AppRouter from "./router"
import { getAccessToken } from "./utils/auth"
import { showErrorNotification } from "./utils/ui"

const App: FC = () => {
  const { isGlobalLoading, isFetchStatus,isFetchAds } = useTypedSelector((state) => state.app)
  const { role } = useTypedSelector((state) => state.currentUser)
  const { isAuth } = useTypedSelector((state) => state.auth)
  const { setAppFetchAds, setAppFetchStatus, currentUserGetData, currentUserGetLocations, currentUserGetStatus, currentUserClearData, setAppLoading } = useActions()

  const [ isSocketConnected, setIsSocketConnected ] = useState(false)

  const connectWebSocket = () => {
    const socket = new WebSocket(`${API_WS_MAIN}?token=${getAccessToken()}`)

    socket.onopen = () => {
      setIsSocketConnected(true)
    }
    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)

        if (updates) {
          if (updates["connection-status"]?.status === true) {
            setAppFetchStatus(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      showErrorNotification("Ошибка при получении данных статуса. Пожалуйста, обновите страницу")
    }
  }

  useEffect(() => {
    if (isAuth && !isGlobalLoading && !isSocketConnected && role !== null && role !== superAdminRole) {
      connectWebSocket()
    }
  }, [ isAuth, isGlobalLoading ])


  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuth && !isGlobalLoading && role !== null && !isFetchAds && role !== superAdminRole) {
          setAppFetchAds(true)
      }
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [ isAuth, isGlobalLoading, role ])

  useEffect(() => {
    if (isAuth && isFetchStatus) {
      currentUserGetStatus()
      setAppFetchStatus(false)
    }
  }, [ isFetchStatus, isAuth ])

  useEffect(() => {
    if (isAuth) {
      setAppLoading(true)

      setTimeout(() => {
        currentUserGetData(
            () => {
              currentUserGetStatus(
                  () => {
                    currentUserGetLocations()
                  },
                  () => {
                    setAppLoading(false)
                  }
              )
            },
            () => {
              setAppLoading(false)
            }
        )
      }, 500)
    } else {
      currentUserClearData()
    }
  }, [ isAuth ])

  return (
      <div id="app" className="App">
        <GlobalErrorWrapper>
          <CallWrapper>
            {
              (isAuth && role) || !isAuth ?
                  <AppRouter />
                  :
                  <div className="global-loading-container">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                  </div>
            }
          </CallWrapper>
        </GlobalErrorWrapper>
      </div>
  )
}

export default App
