import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IParamsRow, IParamsState } from "./types"

const initialState: IParamsState = {
  paramsData: [],
  isLoading: false,
  isUpdate: false
}

export const paramsSlice = createSlice({
  name: "params",
  initialState: initialState,
  reducers: {
    setParamsData: (state, action: PayloadAction<IParamsRow[]>) => {
      state.paramsData = action.payload
    },
    setParamsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setParamsUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const paramsSliceReducer = paramsSlice.reducer
export const paramsSliceActions = paramsSlice.actions
