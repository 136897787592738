import axios, { AxiosResponse } from "axios"

import { ICreateStaffPayload, IUpdateStaffPayload } from "../../types/staff"
import { axiosConfigAuth } from "../middleware"
import {
  API_STAFF_CREATE,
  API_STAFF_DELETE,
  API_STAFF_GET_DATA,
  API_STAFF_GROUPS_LIST,
  API_STAFF_ROLES_LIST, API_STAFF_UPD_DATA, API_STAFF_UPDATE
} from "../urls"

export const staffApi = {
  fetchData: async (): Promise<AxiosResponse> => {
    return axios.get(API_STAFF_GET_DATA, axiosConfigAuth())
  },

  createUser: async (data: ICreateStaffPayload) => {
    return axios.post(API_STAFF_CREATE, data, axiosConfigAuth())
  },

  updateUser: async (data: IUpdateStaffPayload) => {
    return axios.post(API_STAFF_UPDATE, data, axiosConfigAuth())
  },

  deleteUser: async (id: string | number) => {
    return axios.get(`${API_STAFF_DELETE}/${id}`, axiosConfigAuth())
  },

  fetchUpdateData: async (id: string | number) => {
    return axios.get(`${API_STAFF_UPD_DATA}/${id}`, axiosConfigAuth())
  },

  fetchRolesList: (): Promise<AxiosResponse> => {
    return axios.get(API_STAFF_ROLES_LIST, axiosConfigAuth())
  },

  fetchGroupsList: (): Promise<AxiosResponse> => {
    return axios.get(API_STAFF_GROUPS_LIST, axiosConfigAuth())
  },
}
