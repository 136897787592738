import React, { FC } from "react"

import MainLayout from "../../components/layouts/MainLayout"
import PageHead from "../../components/page-header/PageHeader"
import StaffPage from "../../components/pages/staff/StaffPage"

const Staff: FC = () => {
  return (
    <MainLayout pageTitle="Сотрудники">
      <div className="page-wrapper">
        <PageHead title="Сотрудники" />

        <StaffPage />
      </div>
    </MainLayout>
  )
}

export default Staff
