import React, { FC, useEffect, useState } from "react"

import { Form, Input, Modal, Select } from "antd"
import { useDispatch } from "react-redux"

import { staffApi } from "../../../api/endpoints/staffApi"
import { handleResponseError } from "../../../api/middleware"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { IListGeneric } from "../../../types/main"
import { showFormErrors, showSuccessNotification } from "../../../utils/ui"

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
}

const AddModal: FC<Props> = ({ open, setOpen }) => {

  const dispatch = useDispatch()

  const [ form ] = Form.useForm()

  // const [ roles, setRoles ] = useState<IListGeneric[]>([])
  const [ groups, setGroups ] = useState<IListGeneric[]>([])
  // const [ rolesLoading, setRolesLoading ] = useState(false)
  const [ groupsLoading, setGroupsLoading ] = useState(false)

  const { isLoading } = useTypedSelector((state) => state.staff)
  const { staffCreateUser, staffFetchData } = useActions()

  useEffect(() => {
    if (open) {
      // getRolesList()
      getGroupsList()
    }
  }, [ open ])

  // const getRolesList = () => {
  //   setRolesLoading(true)
  //
  //   staffApi.fetchRolesList()
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setRoles(response.data)
  //       }
  //     })
  //     .catch((err) => {
  //       handleResponseError(dispatch, err)
  //     })
  //     .finally(() => {
  //       setRolesLoading(false)
  //     })
  // }

  const getGroupsList = () => {
    setGroupsLoading(true)

    staffApi.fetchGroupsList()
      .then((response) => {
        if (response.status === 200) {
          setGroups(response.data)
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        setGroupsLoading(false)
      })
  }

  const addStaff = (values: any) => {
    staffCreateUser(values, () => {
      setOpen(false)
      showSuccessNotification("Пользователь создан")
      staffFetchData()
    }, (errors) => {
      showFormErrors(form, errors)
    })
  }

  return (
    <Modal
      title="Добавление сотрудника"
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
      okText="Добавить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={addStaff}
        layout="vertical"
        requiredMark={false}
      >

        <Form.Item
          label="ФИО"
          name="name"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Логин пользователя"
          name="username"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        {/*<Form.Item*/}
        {/*  label="Группа"*/}
        {/*  name="group_id"*/}
        {/*  rules={[ { required: true, message: "Поле обязательное" } ]}*/}
        {/*>*/}
        {/*  <Select loading={groupsLoading}>*/}
        {/*    {*/}
        {/*      groups.map((item, index) => (*/}
        {/*        <Select.Option value={item.id} key={index}>*/}
        {/*          {item.name}*/}
        {/*        </Select.Option>*/}
        {/*      ))*/}
        {/*    }*/}
        {/*  </Select>*/}
        {/*</Form.Item>*/}

        {/*<Form.Item*/}
        {/*  label="Роль"*/}
        {/*  name="role_id"*/}
        {/*  rules={[ { required: true, message: "Поле обязательное" } ]}*/}
        {/*>*/}
        {/*  <Select loading={rolesLoading}>*/}
        {/*    {*/}
        {/*      roles.map((item, index) => (*/}
        {/*        <Select.Option value={item.id} key={index}>*/}
        {/*          {item.name}*/}
        {/*        </Select.Option>*/}
        {/*      ))*/}
        {/*    }*/}
        {/*  </Select>*/}
        {/*</Form.Item>*/}
      </Form>
    </Modal>
  )
}

export default AddModal
