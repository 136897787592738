import React, { FC } from "react"

import { Tag } from "antd"

interface IProps {
  index?: number
  color?: string
  defaultColor?: boolean
  pointer?: boolean
  onClick?: () => void
  children?: any
}

const ColorTag: FC<IProps> = ({ children, color, defaultColor, onClick, pointer = false }) => {
  return defaultColor ? (
    <Tag
      style={{ marginTop: 2, marginBottom: 2, cursor: pointer ? "pointer" : "default" }}
      onClick={onClick}
    >
      {children}
    </Tag>
  ) : (
    <Tag
      color={color ? color : ""}
      style={{ marginTop: 2, marginBottom: 2, cursor: pointer ? "pointer" : "default" }}
      onClick={onClick}
    >
      {children}
    </Tag>
  )
}

export default ColorTag
