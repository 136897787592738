import React, { FC } from "react"

import { Routes, Route, Navigate } from "react-router-dom"

import ProtectedRouteWrapper from "./components/wrappers/roleWrapper/ProtectedRouteWrapper"
import { adminRole, demoRole, staffRole, staffSingle, staffSingleAgency, superAdminRole } from "./constants/roles"
import { routeNames } from "./constants/routes"
import { useAllowedRoles } from "./hooks/useAllowedRoles"
import { useTypedSelector } from "./hooks/useTypedSelector"
import Access from "./pages/access"
import Login from "./pages/auth/login"
import ErrorPage from "./pages/error"
import Favorites from "./pages/favorites"
import Home from "./pages/home/Home"
import NotFound from "./pages/not-found"
import CallSettings from "./pages/settings/call-settings"
import Staff from "./pages/staff"

const AppRouter: FC = () => {
  const isUser = useAllowedRoles([ staffSingle, staffSingleAgency, staffRole, demoRole ])
  const isAdmin = useAllowedRoles([ adminRole ])
  const isSuperAdmin = useAllowedRoles([ superAdminRole ])

  const { isAuth } = useTypedSelector((state) => state.auth)

  return (
    <Routes>
      {
        isAuth ?
          <>
            <Route
              path={routeNames.home}
              element={
                <ProtectedRouteWrapper passIf={isAdmin || isUser} navigateTo={routeNames.access}>
                  <Home />
                </ProtectedRouteWrapper>
              }
            />
            <Route
              path={routeNames.favorites}
              element={
                <ProtectedRouteWrapper passIf={isAdmin || isUser} navigateTo={routeNames.access}>
                  <Favorites />
                </ProtectedRouteWrapper>
              }
            />
            <Route
              path={routeNames.staff}
              element={
                <ProtectedRouteWrapper passIf={isAdmin} navigateTo={routeNames.home}>
                  <Staff />
                </ProtectedRouteWrapper>
              }
            />
            <Route
              path={routeNames.access}
              element={
                <ProtectedRouteWrapper passIf={isSuperAdmin} navigateTo={routeNames.home}>
                  <Access />
                </ProtectedRouteWrapper>
              }
            />

            <Route
              path={routeNames.settings_call}
              element={
                <ProtectedRouteWrapper passIf={isAdmin} navigateTo={routeNames.home}>
                  <CallSettings />
                </ProtectedRouteWrapper>
              }
            />
            {/*<Route*/}
            {/*  path={routeNames.settings_bl}*/}
            {/*  element={*/}
            {/*    <ProtectedRouteWrapper passIf={isAdmin} navigateTo={routeNames.home}>*/}
            {/*      <BlackList />*/}
            {/*    </ProtectedRouteWrapper>*/}
            {/*  }*/}
            {/*/>*/}

            <Route
              path={routeNames.notFound}
              element={<NotFound />}
            />
            <Route
              path={routeNames.error}
              element={<ErrorPage />}
            />

            {
              isSuperAdmin ?
                <Route
                  path="/"
                  element={<Navigate to={routeNames.access} replace />}
                />
                :
                null
            }
            <Route
              path="*"
              element={<Navigate to={routeNames.home} replace />}
            />
          </>
          :
          <>
            <Route
              path={routeNames.login}
              element={<Login />}
            />

            <Route
              path="*"
              element={<Navigate to={routeNames.login} replace />}
            />
          </>
      }
    </Routes>
  )
}

export default AppRouter
