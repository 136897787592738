import React, { FC, useEffect } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { DatePicker, Form, Input, Modal, Select, Spin } from "antd"
import dayjs from "dayjs"

import { accessApi } from "../../../api/endpoints/accessApi"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { showErrorNotification, showFormErrors, showSuccessNotification } from "../../../utils/ui"

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
  id: number
}

const EditModal: FC<Props> = ({ open, setOpen, id }) => {

  const [ form ] = Form.useForm()

  const { isLoading, lists, listsLoading } = useTypedSelector((state) => state.access)
  const { accessUpdate, accessFetchData, setAccessLoading } = useActions()

  useEffect(() => {
    if (open && id) {
      setAccessLoading(true)

      accessApi.updateInfo(id)
        .then((response) => {
          if (response.status === 200 && response.data) {
            const data = response.data
            form.setFieldValue("name", data.name)
            form.setFieldValue("username", data.username)
            form.setFieldValue("password", data.password)
            form.setFieldValue("group_id", data.group_id)
            form.setFieldValue("role_id", data.role_id)
            form.setFieldValue("ip_address", data.ip_address)
            form.setFieldValue("access_to", dayjs(data.access_to))
            form.setFieldValue("comment", data.comment)
          }
        })
        .catch(() => {
          showErrorNotification("Ошибка при получении информации, повторите попытку позже.")
          setOpen(false)
        })
        .then(() => {
          setAccessLoading(false)
        })
    }
  }, [ open ])

  const updateAccess = (values: any) => {
    const parsedValues = {
      id: id,
      ...values,
      access_to: dayjs(values.access_to).format("YYYY-MM-DD"),
    }

    accessUpdate(parsedValues, () => {
      setOpen(false)
      showSuccessNotification("Доступ обновлен")
      accessFetchData()
    }, (errors) => {
      showFormErrors(form, errors)
    })
  }

  return (
    <Modal
      title="Редактирование доступа"
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
      okText="Обновить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading }}
      destroyOnClose
    >
      {isLoading ? (
        <div className="global-loading-container" style={{ height: 550 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <Form
          className="un-form__wrapper"
          form={form}
          onFinish={updateAccess}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            label="Название"
            name="name"
            rules={[ { required: true, message: "Поле обязательное" } ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Логин"
            name="username"
            rules={[ { required: true, message: "Поле обязательное" } ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[ { required: true, message: "Поле обязательное" } ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Группа"
            name="group_id"
          >
            <Select loading={listsLoading} disabled>
              {
                lists?.groups?.map((item: any, index: number) => (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            label="IP адрес"
            name="ip_address"
            rules={[ { required: true, message: "Поле обязательное" } ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Доступ до"
            name="access_to"
            rules={[ { required: true, message: "Поле обязательное" } ]}
          >
            <DatePicker
              format="DD.MM.YYYY"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="Комментарий"
            name="comment"
          >
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default EditModal
