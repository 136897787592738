import React, { FC } from "react"

import MainLayout from "../../components/layouts/MainLayout"
import PageHead from "../../components/page-header/PageHeader"
import AccessPage from "../../components/pages/access/AccessPage"

const Access: FC = () => {
  return (
    <MainLayout pageTitle="Доступы">
      <div className="page-wrapper">
        <PageHead title="Доступы" />

        <AccessPage />
      </div>
    </MainLayout>
  )
}

export default Access
