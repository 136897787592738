import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import { API_PARAMS_GET_DATA, API_PARAMS_UPDATE } from "../urls"

export const paramsApi = {
  fetchData: async (): Promise<AxiosResponse> => {
    return axios.get(API_PARAMS_GET_DATA, axiosConfigAuth())
  },

  updateData: async (data: { id: number, value: string }): Promise<AxiosResponse> => {
    return axios.post(API_PARAMS_UPDATE, data, axiosConfigAuth())
  },
}
