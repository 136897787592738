import React, { FC, useEffect } from "react"

import { useNavigate } from "react-router-dom"

import { routeNames } from "../../../constants/routes"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"

interface Props {
  children?: any
}

const GlobalErrorWrapper: FC<Props> = ({ children }) => {
  const navigate = useNavigate()

  const { setAppGlobalError } = useActions()
  const { isGlobalError } = useTypedSelector((state) => state.app)

  useEffect(() => {
    if (isGlobalError) {
      navigate(routeNames.error)
      setAppGlobalError(false)
    }
  }, [ isGlobalError ])

  return (
    <>
      {children}
    </>
  )
}

export default GlobalErrorWrapper
