import { authSliceActions } from "./authSlice"
import { authApi } from "../../../api/endpoints/authApi"
import { clearStorage, getAccessToken, saveAccessToken } from "../../../utils/auth"
import { showErrorNotification } from "../../../utils/ui"
import { TAppDispatch } from "../../store"
import { currentUserActions } from "../currentUser/actions"

export const authActions = {
  ...authSliceActions,

  authLogin: (username: string, password: string, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
    dispatch(authActions.setAuthLoading(true))

    authApi.login(username, password)
      .then((response) => {
        if (response.status === 200) {
          dispatch(authActions.setIsAuth(true))

          // saveRefreshToken(response.data.refresh_token || "")
          saveAccessToken(response.data.access_token)
          onFinish()
        }
      })
      .catch((err) => {
        console.error(err)
        if (err.response?.status === 400) {
          onFormError(err.response.data)
        } else {
          showErrorNotification("Произошла ошибка, повторите попытку позже.")
        }
      })
      .finally(() => {
        dispatch(authActions.setAuthLoading(false))
      })
  },

  authLogout: (onFinish?: () => void) => (dispatch: TAppDispatch) => {
    authApi.logout(getAccessToken())
      .finally(() => {
        dispatch(authActions.setAuthClear())
        dispatch(currentUserActions.currentUserClearData())
        dispatch(authActions.setIsAuth(false))

        clearStorage()
        window.location.href = "/login"

        onFinish && onFinish()
      })
  },

  // authResetPass: (email: string, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
  //     dispatch(authActions.setAuthLoading(true))
  //
  //     authApi.resetPassword(email)
  //         .then((response) => {
  //             if (response.status === 200) {
  //                 showSuccessNotification("Письмо с для восстановления пароля отправлено на вашу почту")
  //                 onFinish()
  //             } else {
  //                 showErrorNotification("Произошла ошибка, повторите попытку позже.")
  //             }
  //         })
  //         .catch((err) => {
  //             if (err.response?.status === 400) {
  //                 onFormError(err.response.data)
  //             } else {
  //                 showErrorNotification("Произошла ошибка, повторите попытку позже.")
  //             }
  //         })
  //         .finally(() => {
  //             dispatch(authActions.setAuthLoading(false))
  //         })
  // },

  // authRestorePassword: (token: string, password: string, onFinish: () => void) => async (dispatch: TAppDispatch) => {
  //     dispatch(authActions.setAuthLoading(true))
  //
  //     const fingerprint = await getFingerPrint()
  //
  //     authApi.recoveryPassword(token, password, fingerprint)
  //         .then((response) => {
  //             if (response.status === 200) {
  //                 showSuccessNotification("Пароль обновлен. Войдите заного в сой аккаунт.")
  //                 onFinish()
  //             } else {
  //                 showErrorNotification("Произошла ошибка, повторите попытку позже.")
  //             }
  //         })
  //         .catch((err) => {
  //             if (err.response?.status === 400) {
  //                 showErrorNotification("Пользователь не найден.")
  //             } else {
  //                 showErrorNotification("Произошла ошибка, повторите попытку позже.")
  //             }
  //         })
  //         .finally(() => {
  //             dispatch(authActions.setAuthLoading(false))
  //         })
  // }
}
